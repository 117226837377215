/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

import PreSprouted from './components/pre-sprouted/PreSprouted';
import HeroBanner from './components/hero-banner/HeroBanner';
import HarvestSection from './components/harvest-section/HarvestSection';
import FarmTableSection from './components/farm-table-section/FarmTableSection';
import SliderSection from './components/slider-section/SliderSection';

import { PressReviewSlider, MarketingContainer } from 'components';

import { COMMON_SECTIONS } from 'constants/contentful';

const sections = {
  GROWING: '3KDY88i0R2WWgJLDutU7YM',
  PRE_SPROUTED: 'fURedAq9ChuDtJPr77LRs',
  GROW_IN: '3q9GAMH3NHWhPZSmX035la',
  GROW10: '6w0LFYY7oIoQtdztG3ZFCU',
  FARM_TABLE: '4I3ls92Q9zAxvRm25LWu62',
  SLIDE: '743GnbUmH77iWrHvJ3sHGm',
  ZOOEY: '5qH9RCO07TqZeaGfMXdYbx',
  HERO_BANNER: '5UjRbxO4vacnUdkUSq0fhG',
};

export const pageSpecificMap = {
  [sections.HERO_BANNER]: ({ title, body, callToAction, isTextInverted }) => (
    <HeroBanner
      key={sections.HERO_BANNER}
      title={title}
      body={body}
      callToAction={callToAction}
      modifiers={['mobileAlignCenter', 'brandFont']}
      imageRouteLink={callToAction?.fields?.linkUrl}
      isTextInverted={isTextInverted}
    />
  ),
  [COMMON_SECTIONS.BRANDS]: ({ body, percentages }) => <PressReviewSlider key='pressSlider' body={body} brands={percentages} showCallout />,
  [COMMON_SECTIONS.MAKER]: (fields, index) => (
    <MarketingContainer key={COMMON_SECTIONS.MAKER} makerSectionId={index} modifiers='minLoadingHeight' />
  ),
  [sections.PRE_SPROUTED]: ({ title, body, callToAction }) => (
    <PreSprouted key={sections.PRE_SPROUTED} title={title} body={body} callToAction={callToAction} />
  ),
  [sections.ZOOEY]: ({ title, body, callToAction }) => (
    <HarvestSection key={sections.ZOOEY} title={title} body={body} callToAction={callToAction} />
  ),
  [sections.FARM_TABLE]: (fields) => (
    <FarmTableSection
      key={sections.FARM_TABLE}
      title={fields.title}
      body={fields.body}
      callToAction={fields.callToAction}
    ></FarmTableSection>
  ),
  [sections.SLIDE]: (fields) => (
    <SliderSection key={sections.SLIDE} contentBlock={fields.contentBlock} title={fields.title} body={fields.body}></SliderSection>
  ),
};
